<script>
export default {
  name: 'XzTagGroup',
  props: {
    color: {
      type: String,
      default: 'blue',
    },
    hasBorder: {
      type: Boolean,
      default: false,
    },
    hasBoundary: {
      type: Boolean,
      default: true,
    },
    data: {
      type: Array,
      default: () => [],
    },
    active: {
      type: [Number, String],
      default: '',
    },
  },
  render () {
    const slots = Object.keys(this.$slots).map(slot => {
      return (
        this.$slots[slot]
      );
    });

    const tagGroup = this.data.map(v =>
      <a-tag class={!this.hasBorder ? 'no-border' : ''} color={this.active === v.value ? 'blue' : ''} onClick={this.hanldleClick.bind(this, v)}>
        {v.label}
      </a-tag>,
    );

    return (
      <div class={`xz-tag-group ${this.hasBoundary ? 'boundary' : ''}`}>
        {slots}
        {tagGroup}
      </div>
    );
  },
  data () {
    return {};
  },
  methods: {
    hanldleClick (v) {
      this.$emit('handleClick', v);
    },
  },
};
</script>
<style lang="scss" scoped>
.xz-tag-group {
  background-color: #fff;
  padding: 12px;

  &.boundary {
    border-bottom: 0.5px solid #DFE2E8;
  }

  .no-border {
    &.ant-tag {
      border-width: 0;
    }
  }

  .ant-tag {
    font-size: 14px;
    padding: 6px;
    cursor: pointer;
  }
}
</style>
